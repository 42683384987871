import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import blogoverlay1 from '../../assets/images/blogs/blogoverlay1.png';
import blogoverlay2 from '../../assets/images/blogs/blogoverlay2.png';
import { Link } from "react-router-dom";
import latestblog1 from '../../assets/images/blogs/latestblogimg2.jpeg'
import blogee2 from '../../assets/images/blogs/blogee2.jpg'
import blogee3 from '../../assets/images/blogs/3.jpg'
import blogoverlay from '../../assets/images/blogs/blogoverlay.png';

const RecentBlogs = () => {
  const [hovered, setHovered] = useState(null);

  return (
    <div className="mb-50 mt-20" style={styles.cardContainer}>
      
          <div className="pb-2 mb-5">
          <h3 className="text-center" style={{ color:'#072f5f', display: 'inline', fontWeight:'bold', fontSize:'30px' }}>Insights from Boston Financial</h3>
          </div>

      <div style={styles.row}>
        {/* card1 */}
        <Link
        to='/increasing-incidence-of-financial'
          style={styles.card}
          onMouseEnter={() => setHovered(1)}
          onMouseLeave={() => setHovered(null)}
        >
          <div
            style={{
              ...styles.cardImage,
              backgroundImage: `url(${blogee3})`,
            }}
          >
            <div
              className={`cardOverlay ${hovered === 1 ? 'overlayVisible' : ''}`}
              style={{
                ...styles.cardOverlay1,
                ...(hovered === 1 ? styles.overlayVisible : {}),
              }}
            ></div>
            <div style={styles.cardDateContainer}>
              <div style={styles.cardDate}>Dec 11, 2024</div>
              <div style={styles.cardDateLine}></div>
            </div>
            <div style={styles.cardTitle}>
            The Increasing Incidence of Financial Statement Retractions: <br/> Implications and Solutions for United States Companies 
            </div>
          </div>
        </Link>
        {/* card1 */}

        {/* card2 */}
        <Link
        to='/Supreme-court-defers-fundamental'
          style={styles.card}
          onMouseEnter={() => setHovered(2)}
          onMouseLeave={() => setHovered(null)}
        >
          <div
            style={{
              ...styles.cardImageSmall,
              backgroundImage: `url(${blogee2})`,
            }}
          >
            <div
              className={`cardOverlay ${hovered === 2 ? 'overlayVisible' : ''}`}
              style={{
                ...styles.cardOverlay2,
                ...(hovered === 2 ? styles.overlayVisible : {}),
              }}
            ></div>
            <div style={styles.cardDateContainer}>
              <div style={styles.cardDate}>Dec 7, 2024</div>
              <div style={styles.cardDateLine}></div>
            </div>
            <div style={styles.cardTitle2}>
            Supreme Court Defers Fundamental Constitutional Question in Upholding Tax on Foreign Business Income
            </div>
          </div>
        </Link>
        {/* card2 */}
      </div>

      <div style={styles.row}>
        {/* card3 */}
        <Link
        to='/IRS-again-delays-effective-date'
          style={styles.card}
          onMouseEnter={() => setHovered(3)}
          onMouseLeave={() => setHovered(null)}
        >
          <div
            style={{
              ...styles.cardImageNoBorder,
              backgroundImage: `url(${blogoverlay})`,
            }}
          >
            <div
              className={`cardOverlay ${hovered === 3 ? 'overlayVisible' : ''}`}
              style={{
                ...styles.cardOverlay3,
                ...(hovered === 3 ? styles.overlayVisible : {}),
              }}
            ></div>
            <div style={styles.cardDateContainer}>
              <div style={styles.cardDate}>Dec 4, 2024</div>
              <div style={styles.cardDateLine}></div>
            </div>
            <div style={styles.cardTitle}>
            IRS again delays effective date of $600 threshold for <br/>Form 1099-K reporting
            </div>
          </div>
        </Link>
        {/* card3 */}

        {/* card4 */}
        <Link
        to='/navigating-crypto'
          style={styles.card}
          onMouseEnter={() => setHovered(4)}
          onMouseLeave={() => setHovered(null)}
        >
          <div
            style={{
              ...styles.cardImageNoBorder4,
              backgroundImage: `url(${blogoverlay1})`,
            }}
          >
            <div
              className={`cardOverlay ${hovered === 4 ? 'overlayVisible' : ''}`}
              style={{
                ...styles.cardOverlay4,
                ...(hovered === 4 ? styles.overlayVisible : {}),
              }}
            ></div>
            <div style={styles.cardDateContainer}>
              <div style={styles.cardDate}>August 11, 2024</div>
              <div style={styles.cardDateLine}></div>
            </div>
            <div style={styles.cardTitle}>
            Navigating the Crypto Landscape: What Investors Should Know in 2024
            </div>
          </div>
        </Link>
        {/* card4 */}

        {/* card5 */}
        <Link
        to='/financial-services'
          style={styles.card}
          onMouseEnter={() => setHovered(5)}
          onMouseLeave={() => setHovered(null)}
        >
          <div
            style={{
              ...styles.cardImageNoBorder5,
              backgroundImage: `url(${blogoverlay2})`,
            }}
          >
            <div
              className={`cardOverlay ${hovered === 5 ? 'overlayVisible' : ''}`}
              style={{
                ...styles.cardOverlay5,
                ...(hovered === 5 ? styles.overlayVisible : {}),
              }}
            ></div>
            <div style={styles.cardDateContainerOpposite}>
              <div style={styles.cardDateOpposite}>August 7, 2024</div>
              <div style={styles.cardDateLineOpposite}></div>
            </div>
            <div style={styles.cardTitle}>
            The Rise of AI in Financial Services: What Investors Need to Know
            </div>
          </div>
        </Link>
        {/* card5 */}

      </div>

    </div>
  );
};

const styles = {
  cardContainer: {
    display: "flex",
    flexDirection: "column", // Stack rows vertically
    alignItems: "center",
  },
  row: {
    display: "flex",
    gap: "20px",
    marginBottom: "20px",
  },
  card: {
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
  },
  cardImage: {
    width: "780px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderBottomRightRadius: '39%',
    clipPath: "polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0 100%)",
    position: "relative",
    transition: "transform 0.3s ease",
    marginRight:'0px',
  },
  cardImage6: {
    width: "1200px",
    height: "400px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    // borderBottomRightRadius: '39%',
    // clipPath: "polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0 100%)",
    position: "relative",
    transition: "transform 0.3s ease",
    marginRight:'0px',
  },
  cardImageSmall: {
    width: "400px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderBottomLeftRadius: '40%',
    position: "relative",
    transition: "transform 0.3s ease",
    marginLeft:'0px',
  },
  cardImageNoBorder: {
    width: "400px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  cardImageNoBorder4: {
    width: "380px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderTopRightRadius: '45%',
    transition: "transform 0.3s ease",
  },
  cardImageNoBorder7: {
    width: "590px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderTopRightRadius: '45%',
    transition: "transform 0.3s ease",
  },
  cardImageNoBorder5: {
    width: "380px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderTopLeftRadius: '45%',
    transition: "transform 0.3s ease",
  },
  cardImageNoBorder8: {
    width: "590px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderTopLeftRadius: '45%',
    transition: "transform 0.3s ease",
  },
  cardOverlay1: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderBottomRightRadius: '39%',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
  },
  cardOverlay6: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    // borderBottomRightRadius: '39%',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
  },
  cardOverlay2: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderBottomLeftRadius: '40%',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
  },
  cardOverlay3: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    // borderBottomRightRadius: '38%',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
  },
  cardOverlay4: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderTopRightRadius: '45%',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
  },
  cardOverlay7: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderTopRightRadius: '45%',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
  },
  cardOverlay5: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderTopLeftRadius: '45%',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
  },
  cardOverlay8: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderTopLeftRadius: '45%',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
  },
  overlayVisible: {
    backgroundColor: 'rgba(0, 112, 255, 0.61)',
},
  cardDateContainer: {
    position: "absolute",
    top: "10px",
    left: "10px",
    display: "flex",
    alignItems: "center",
  },
  cardDate: {
    color: "white",
    background: "rgba(0, 0, 0, 0.5)",
    padding: "5px 10px",
    borderRadius: "5px",
  },
  cardDateLine: {
    flexGrow: 1,
    height: "2px",
    background: "white",
    marginLeft: "10px",
  },
  cardTitle: {
    position: "absolute",
    bottom: "10px",
    left: "10px",
    right: "10px",
    color: "white",
    fontSize: "22px",
    fontWeight:'600',
    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)",
  },
  cardTitle2: {
    position: "absolute",
    bottom: "40px",
    left: "50px",
    right: "10px",
    color: "white",
    fontSize: "22px",
    fontWeight:'600',
    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)",
  },
  cardDateContainerOpposite: {
    position: "absolute",
    top: "10px",
    right: "10px",
    display: "flex",
    alignItems: "center",
  },
  cardDateOpposite: {
    color: "white",
    background: "rgba(0, 0, 0, 0.5)",
    padding: "5px 10px",
    borderRadius: "5px",
  },
  cardDateLineOpposite: {
    flexGrow: 1,
    height: "2px",
    background: "white",
    marginRight: "10px",
  },

  
}

export default RecentBlogs;
